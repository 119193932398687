/* .sideShadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
} */

.mainLi {
  background: white;
}

.mainLi:hover {
  background: linear-gradient(91.68deg, #27295B 0%, #1CBE93 97.14%);
  color: white;
  transition: all;
  transition-duration: 300;
  font-weight: 600 !important;
}

.mainLi.active {
  background: linear-gradient(91.68deg, #27295B 0%, #1CBE93 97.14%);
  color: white !important;
  transition: all;
  transition-duration: 300;
  font-weight: 600 !important;
}
.mainLi.active span.text-base.font-normal { 
  font-weight: 600 !important;
}

.sideMenuBg {
  background: linear-gradient(91.68deg, #27295B 0%, #1CBE93 97.14%);
}

.mainHead {
  background: linear-gradient(94.46deg, #27295B 0%, #1CBE93 110.28%);

}

.mainHead {
  border-radius: 20px 20px 0px 0px;
}


.AnnoncesA {
  background: linear-gradient(89.48deg, #1CBE93 0.38%, #54E1BC 99.55%);
  border-radius: 14px;
}

.AnnoncesB {
  background: linear-gradient(90deg, #602DB4 0%, #996BE6 100%);
  border-radius: 14px;
}

.AnnoncesC {
  background: linear-gradient(89.96deg, #27295B 0.03%, #686CCD 99.97%);
  border-radius: 14px;
}

.AnnonTab .Mui-selected {
  background-color: #1CBE93 !important;
  color: #fff !important;

}




.search-box {
  background: #fff;
  height: 56px;
  border-radius: 50px;

}
.alert {
  padding: 15px;
  margin: 10px 0;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;
  opacity: 0.9;
  color: #fff;
}

.alert.success { background-color: #4CAF50; }
.alert.error { background-color: #f44336; }
.alert.warning { color: #8a6d3b; background-color: #fcf8e3;border-color: #faebcc;}
.alert.info { color: #31708f;background-color: #d9edf7;border-color: #bce8f1; }
.alert.info a {
  color: #1e6cf5;
}
/* input */
.search-input {
  outline: none;
  border: none;
  background: none;
  width: 0;
  padding: 0;
  color: #27295B;
  float: left;
  font-size: 16px;
  transition: .3s;
  line-height: 56px;
  text-indent: 2px;
}

.search-input::placeholder {
  color: #dbc5b0;
}

/* icon */
.search-btn {
  color: #27295B;
  float: right;
  width: 56px;
  height: 56px;
  border-radius: 50px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: .3s;
}

.search-input:focus,
.search-input:not(:placeholder-shown) {
  width: 240px;
  padding: 0 6px;
}

.search-box:hover>.search-input {
  width: 240px;
  padding: 0 6px;
}

.search-box:hover>.search-btn,
.search-input:focus+.search-btn,
.search-input:not(:placeholder-shown)+.search-btn {
  background: #fff;
  color: #27295B;
}

.paramTabBar .MuiTabs-flexContainer {
  gap: 15px;
}





.badgeTops .MuiBadge-badge {
  background-color: #1CBE93;
}

text.MuiChartsAxis-tickLabel tspan:dominant-baseline(hanging) {
  font-weight: 800 !important;
  font-size: 12px;
}

.braWidthHeight {
  width: 100% !important;

}



.fc .fc-timegrid-axis-cushion {
  font-size: 12px;
  text-align: center;
}
button.fc-listWeek-button.fc-button.fc-button-primary {
  display: none;
}
button.fc-timeGridDay-button.fc-button.fc-button-primary{
  border-bottom-right-radius: 0.25em !important;
    border-top-right-radius: 0.25em !important;
}
.fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none !important;
}



@media screen and (max-width: 992px) {
  .braWidthHeight {
    width: 100% !important;
    height: auto !important;
  }
}

@media screen and (max-width: 600px) {
  .paramTabBar .MuiTabs-flexContainer {
    justify-content: space-between;
    gap: 8px;
  }

  .paramTabBar .MuiTabs-flexContainer .MuiButtonBase-root.MuiTab-root {
    font-size: 0.8rem;

  }

  .agenda-container .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    flex-direction: column;
    margin-top: 13px;
  }
}

@media screen and (max-width: 500px) {
.fc .fc-col-header-cell-cushion { 
  font-size: 13px !important;
}
.fc-view-harness.fc-view-harness-active {
  height: 377px !important;
}
.fc-toolbar-chunk {
  margin: 7px 0;
}
}
@media screen and (max-width: 400px) {
  .fc .fc-col-header-cell-cushion { 
    font-size: 9px !important;
  } 
 
    .paramTabBar .MuiTabs-flexContainer {
      justify-content: space-around;
      gap: 2px;
    }
  
    .paramTabBar .MuiTabs-flexContainer .MuiButtonBase-root.MuiTab-root {
      font-size: 0.7rem;
      padding: 0 7px;
      height: 40px !important;
      min-height: 40px !important;
  
    }
  }